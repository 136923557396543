import ReactGA from "react-ga";

export const initGA = (trackingID: string) => {
  ReactGA.initialize(trackingID);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const handleTestButtonClick = () => {
  window.gtag("event", "click", {
    event_category: "User Interaction",
    event_label: "Test internet button",
  });
};
