import React, { useEffect, useState, useCallback, useRef } from "react";
import spinningFan1 from "../assets/images/spinningFan.png";
import slowFan from "../assets/images/slowerFan.png";
import fasterfan from "../assets/images/fasterFan1.png";
import ToggleSwitch from "./ToggleSwitch";
import { toast } from "react-toastify";
import Button from "./Button";
import { handleTestButtonClick } from "../utils/analytics";
import usePageTracking from "../hooks/usePageTracking";

const SpeedTestWeb: React.FC = () => {
  usePageTracking();

  const playButtonRef = useRef<HTMLButtonElement>(null);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [speedStatus, setSpeedStatus] = useState("");
  const [internetSpeed, setInternetSpeed] = useState<number | null>(null);
  const [showCalculatingMessage, setShowCalculatingMessage] = useState(false);
  const [allowedNotification, setAllowedNotification] = useState(false);
  const [speedTestCheckInterval, setSpeedTestCheckInterval] = useState(
    localStorage.getItem("sliderDuration") || 600000
  );
  const [rangeSliderValue, setRangeSliderValue] = useState(1);
  const [sliderTitle, setSliderTitle] = useState("");
  const [allowAlert, setAllowAlert] = useState(
    localStorage.getItem("allowAlert") === "yes" ? true : false
  );
  const [allowAudio, setAllowAudio] = useState(
    localStorage.getItem("allowAudio") === "yes" ? true : false
  );
  const [notificationPermission, setNotificationPermission] = useState(
    localStorage.getItem("notificationPermission") || "default"
  );

  const askNotificationPermission = useCallback(() => {
    function handlePermission(permission: any) {
      if (
        Notification.permission === "denied" ||
        Notification.permission === "default"
      ) {
        setAllowedNotification(false);
        setNotificationPermission(Notification.permission);
      } else {
        setAllowedNotification(true);
        setNotificationPermission(Notification.permission);
      }
    }

    // Check if the browser supports notifications
    if (!Reflect.has(window, "Notification")) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(handlePermission);
      } else {
        Notification.requestPermission(handlePermission);
      }
    }
  }, []);

  useEffect(() => {
    askNotificationPermission();
  }, [askNotificationPermission]);

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  const createStatusNotification = useCallback(() => {
    const img = "assets/logo.png";
    const textResult = !isOnline
      ? "Connected to the Internet! 😎"
      : "Disconnected from the Internet 😔";
    const text = textResult;
    new Notification("Internet Status", {
      body: text,
      icon: img,
    });
  }, [isOnline]);

  const createSpeedNotification = useCallback(() => {
    const img = "assets/logo.png";
    new Notification("Internet speed", {
      body: `Current internet speed is ${internetSpeed} mbps`,
      icon: img,
    });
  }, [internetSpeed]);

  const storedAllowAudio = useCallback(() => {
    localStorage.getItem("allowAudio");
    if (allowAudio) {
      localStorage.setItem("allowAudio", "yes");
    } else {
      localStorage.setItem("allowAudio", "no");
    }
  }, [allowAudio]);

  useEffect(() => {
    storedAllowAudio();
  }, [storedAllowAudio]);

  const storedAllowAlert = useCallback(() => {
    localStorage.getItem("allowAlert");
    if (allowAlert) {
      localStorage.setItem("allowAlert", "yes");
    } else {
      localStorage.setItem("allowAlert", "no");
    }
  }, [allowAlert]);

  useEffect(() => {
    storedAllowAlert();
  }, [storedAllowAlert]);

  const notify = (msg: string) =>
    toast.info(msg, {
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  // OLD CLOUDDINARY
  // const imageAddr =
  //   "https://res.cloudinary.com/ddyvbmcrl/image/upload/v1699539526/new_xvauwd.jpg";
  // const downloadSize = 4791992.32; // bytes = 4.57mb

  // const imageAddr =
  //   "https://upload.wikimedia.org/wikipedia/commons/3/3a/Bloemen_van_adderwortel_%28Persicaria_bistorta%2C_synoniem%2C_Polygonum_bistorta%29_06-06-2021._%28d.j.b%29.jpg";
  // const downloadSize = 7300000; // bytes = 6.96182mb

  // NEW CLOUDINARY MART47
  // const imageAddr =
  //   "https://res.cloudinary.com/dcoqdzpit/image/upload/v1725953278/new_xvauwd_koxdrp.jpg";
  const downloadSize = 4791992.32; // bytes = 4.57mb

  // IMGBB TESTDRIVE
  const imageAddr = "https://i.ibb.co/M17Hg3D/fan-4-57mb.jpg";

  const showProgressMessage = (msg: string | string[]) => {
    if (console) {
      if (typeof msg === "string") {
        console.log(msg);
      } else {
        msg.forEach((item) => console.log(item));
      }
    }
  };

  const measureConnectionSpeed = useCallback(() => {
    setIsCheckingStatus(true);
    handleTestButtonClick();
    let startTime: number, endTime: number;
    const download = new Image();
    download.onload = function () {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = function () {
      showProgressMessage("Invalid image, or error downloading");
    };

    startTime = new Date().getTime();
    const cacheBuster = "?nnn=" + startTime;
    download.src = imageAddr + cacheBuster;

    function showResults() {
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSize * 8;
      const speedBps: any = (bitsLoaded / duration).toFixed(2);
      const speedKbps: any = (speedBps / 1024).toFixed(2);
      const speedMbps: any = (speedKbps / 1024).toFixed(2);
      showProgressMessage([
        "Your connection speed is:",
        speedBps + " bps",
        speedKbps + " kbps",
        speedMbps + " Mbps",
      ]);
      setInternetSpeed(speedMbps);
      if (speedMbps < 3) {
        setSpeedStatus("very slow");
      } else if (speedMbps >= 3 && speedMbps <= 5) {
        setSpeedStatus("slow");
      } else if (speedMbps > 5 && speedMbps < 10) {
        setSpeedStatus("normal");
      } else if (speedMbps >= 10) {
        setSpeedStatus("blazingly fast 🔥");
      } else return;

      setIsCheckingStatus(false);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!showCalculatingMessage && !internetSpeed) {
        setShowCalculatingMessage(true);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showCalculatingMessage, internetSpeed]);

  useEffect(() => {
    if (showCalculatingMessage && internetSpeed) {
      setShowCalculatingMessage(false);
    }
  }, [internetSpeed, showCalculatingMessage]);

  useEffect(() => {
    measureConnectionSpeed();
  }, [measureConnectionSpeed]);

  useEffect(() => {
    if (playButtonRef.current) {
      playButtonRef.current.click();
    }
  }, []);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      const path1 = require("../assets/audios/int-down.mp3");
      const path2 = require("../assets/audios/int-up.mp3");
      const audioDown = new Audio(path1);
      const audioUp = new Audio(path2);
      setIsOnline((navigator as any).onLine);
      if (!(navigator as any).onLine) {
        if (localStorage.getItem("allowAudio") === "yes") {
          const playAsync = audioDown.play();
          if (playAsync !== undefined) {
            playAsync
              .then(() => {
                console.log("audio started");
              })
              .catch(() => {
                console.log("cant play sound");
              });
          }
        } else {
          console.log("Audio is disabled");
        }
      } else {
        if (localStorage.getItem("allowAudio") === "yes") {
          const playAsync = audioUp.play();
          if (playAsync !== undefined) {
            playAsync
              .then(() => {
                console.log("audio started");
              })
              .catch(() => {
                console.log("cant play sound");
              });
          }
        } else {
          console.log("Audio is disabled");
        }
      }
    };

    window.addEventListener("online", () => {
      handleOnlineStatusChange();
      if (allowAlert) {
        createStatusNotification();
      }
    });

    window.addEventListener("offline", () => {
      handleOnlineStatusChange();
      if (allowAlert) {
        createStatusNotification();
      }
    });

    return () => {
      window.removeEventListener("online", () => {
        handleOnlineStatusChange();
        if (allowAlert) {
          createStatusNotification();
        }
      });
      window.removeEventListener("offline", () => {
        handleOnlineStatusChange();
        if (allowAlert) {
          createStatusNotification();
        }
      });
    };
  }, [allowAlert, allowedNotification, createStatusNotification]);

  const measureAndNotify = useCallback(() => {
    measureConnectionSpeed();
    if (!isCheckingStatus && internetSpeed !== null) {
      createSpeedNotification();
    }
  }, [
    measureConnectionSpeed,
    createSpeedNotification,
    internetSpeed,
    isCheckingStatus,
  ]);

  useEffect(() => {
    // Run the measureAndNotify function every hour
    const intervalId = setInterval(() => {
      measureAndNotify();
    }, +speedTestCheckInterval); // 1 hour in milliseconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [measureAndNotify, speedTestCheckInterval]);

  const sliderValueToDuration = (sliderValue: string) => {
    if (sliderValue === "1") {
      localStorage.setItem("sliderDuration", "600000");
      setSpeedTestCheckInterval(600000);
      setSliderTitle(`Check speed every 10 minutes`);
      return 600000; // 10 minutes
    }
    if (sliderValue === "2") {
      localStorage.setItem("sliderDuration", "1800000");
      setSpeedTestCheckInterval(1800000);
      setSliderTitle(`Check speed every 30 minutes`);
      return 1800000; // 30 minutes
    }
    if (sliderValue === "3") {
      localStorage.setItem("sliderDuration", "3600000");
      setSpeedTestCheckInterval(3600000);
      setSliderTitle(`Check speed every 1 hour`);
      return 3600000; // 1 hour
    }
    if (sliderValue === "4") {
      localStorage.setItem("sliderDuration", "7200000");
      setSpeedTestCheckInterval(7200000);
      setSliderTitle(`Check speed every 2 hours`);
      return 7200000; // 2 hours
    }
    if (sliderValue === "5") {
      localStorage.setItem("sliderDuration", "10800000");
      setSpeedTestCheckInterval(10800000);
      setSliderTitle(`Check speed every 3 hours`);
      return 10800000; // 3 hours
    }
    if (sliderValue === "6") {
      localStorage.setItem("sliderDuration", "14400000");
      setSpeedTestCheckInterval(14400000);
      setSliderTitle(`Check speed every 4 hours`);
      return 14400000; // 4 hours
    }
    if (sliderValue === "7") {
      localStorage.setItem("sliderDuration", "18000000");
      setSpeedTestCheckInterval(18000000);
      setSliderTitle(`Check speed every 5 hours`);
      return 18000000; // 5 hours
    }
    if (sliderValue === "8") {
      localStorage.setItem("sliderDuration", "21600000");
      setSpeedTestCheckInterval(21600000);
      setSliderTitle(`Check speed every 6 hours`);
      return 21600000; // 6 hours
    }
    if (sliderValue === "9") {
      localStorage.setItem("sliderDuration", "25200000");
      setSpeedTestCheckInterval(25200000);
      setSliderTitle(`Check speed every 7 hours`);
      return 25200000; // 7 hours
    }
    if (sliderValue === "10") {
      localStorage.setItem("sliderDuration", "28800000");
      setSpeedTestCheckInterval(28800000);
      setSliderTitle(`Check speed every 8 hours`);
      return 28800000; // 8 hours
    }
    if (sliderValue === "11") {
      localStorage.setItem("sliderDuration", "32400000");
      setSpeedTestCheckInterval(32400000);
      setSliderTitle(`Check speed every 9 hours`);
      return 32400000; // 9 hours
    }
    if (sliderValue === "12") {
      localStorage.setItem("sliderDuration", "36000000");
      setSpeedTestCheckInterval(36000000);
      setSliderTitle(`Check speed every 10 hours`);
      return 36000000; // 10 hours
    }
    if (sliderValue === "13") {
      localStorage.setItem("sliderDuration", "39600000");
      setSpeedTestCheckInterval(39600000);
      setSliderTitle(`Check speed every 11 hours`);
      return 39600000; // 11 hours
    }
    if (sliderValue === "14") {
      localStorage.setItem("sliderDuration", "43200000");
      setSpeedTestCheckInterval(43200000);
      setSliderTitle(`Check speed every 12 hours`);
      return 43200000; // 12 hours
    }
  };

  const storedSliderValue = useCallback(() => {
    const result = localStorage.getItem("sliderDuration");
    if (result === "600000") {
      setSliderTitle(`Check speed every 10 minutes`);
      return setRangeSliderValue(1);
    }
    if (result === "1800000") {
      setSliderTitle(`Check speed every 30 minutes`);
      return setRangeSliderValue(2);
    }
    if (result === "3600000") {
      setSliderTitle(`Check speed every 1 hour`);
      return setRangeSliderValue(3);
    }
    if (result === "7200000") {
      setSliderTitle(`Check speed every 2 hours`);
      return setRangeSliderValue(4);
    }
    if (result === "10800000") {
      setSliderTitle(`Check speed every 3 hours`);
      return setRangeSliderValue(5);
    }
    if (result === "14400000") {
      setSliderTitle(`Check speed every 4 hours`);
      return setRangeSliderValue(6);
    }
    if (result === "18000000") {
      setSliderTitle(`Check speed every 5 hours`);
      return setRangeSliderValue(7);
    }
    if (result === "21600000") {
      setSliderTitle(`Check speed every 6 hours`);
      return setRangeSliderValue(8);
    }
    if (result === "25200000") {
      setSliderTitle(`Check speed every 7 hours`);
      return setRangeSliderValue(9);
    }
    if (result === "28800000") {
      setSliderTitle(`Check speed every 8 hours`);
      return setRangeSliderValue(10);
    }
    if (result === "32400000") {
      setSliderTitle(`Check speed every 9 hours`);
      return setRangeSliderValue(11);
    }
    if (result === "36000000") {
      setSliderTitle(`Check speed every 10 hours`);
      return setRangeSliderValue(12);
    }
    if (result === "39600000") {
      setSliderTitle(`Check speed every 11 hours`);
      return setRangeSliderValue(13);
    }
    if (result === "43200000") {
      setSliderTitle(`Check speed every 12 hours`);
      return setRangeSliderValue(14);
    }
  }, []);

  useEffect(() => {
    storedSliderValue();
  }, [storedSliderValue]);

  return (
    <div
      onClick={() => console.log("window clicked")}
      className="w-full h-full"
    >
      <div className="w-full flex justify-between items-center flex-wrap lg:flex-nowrap">
        <div
          style={{ display: "flex", width: "200px" }}
          title="Switch on sound to know when internet status changes"
        >
          <ToggleSwitch
            label="Sound 🎧"
            isSelected={allowAudio}
            onClick={() => {
              setAllowAudio(!allowAudio);
              notify("Sound updated!");
              if (allowAudio) {
                localStorage.setItem("allowAudio", "yes");
              } else {
                localStorage.setItem("allowAudio", "no");
              }
            }}
          />
        </div>

        <div className="flex justify-end my-4 lg:my-0">
          {notificationPermission !== "granted" ? (
            <Button
              label={
                notificationPermission === "granted"
                  ? "Enabled Notification"
                  : "Enable Notification"
              }
              variant="primary"
              onClick={askNotificationPermission}
            />
          ) : (
            <div className="flex flex-col">
              <div
                style={{ display: "flex", width: "190px" }}
                title="Switch on sound to know when internet status changes"
              >
                <ToggleSwitch
                  label="Desktop alert"
                  isSelected={allowAlert}
                  onClick={() => {
                    setAllowAlert(!allowAlert);
                    notify("Desktop alert updated!");
                    if (allowAlert) {
                      localStorage.setItem("allowAlert", "yes");
                    } else {
                      localStorage.setItem("allowAlert", "no");
                    }
                  }}
                />
              </div>

              <div className="w-full lg:w-[300px] my-4">
                <div className="w-auto">
                  <div className="text-xs font-[700] w-full flex justify-between">
                    <p>10min</p>
                    <p>12 hours</p>
                  </div>
                </div>
                <input
                  className="w-full"
                  type="range"
                  id="durationSlider"
                  min="1"
                  max="14"
                  step="1"
                  value={rangeSliderValue}
                  onChange={(event) => {
                    const result = event.target.value;
                    setRangeSliderValue(+result);
                    sliderValueToDuration(result);
                  }}
                />
                <p className="text-xs w-full">{sliderTitle}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {isOnline && showCalculatingMessage && (
        <div>
          <p>Still testing... internet possibly slow</p>
        </div>
      )}
      <div>
        {isOnline ? (
          <div>
            <div className="image-div">
              <img
                className="image-el"
                src={
                  internetSpeed && internetSpeed < 3
                    ? slowFan
                    : internetSpeed && internetSpeed >= 3 && internetSpeed <= 5
                    ? spinningFan1
                    : internetSpeed && internetSpeed > 5 && internetSpeed < 10
                    ? spinningFan1
                    : internetSpeed && internetSpeed >= 10
                    ? fasterfan
                    : slowFan
                }
                alt="Internet connected!"
              />
            </div>
            <p>Connected to the Internet! 😎</p>
          </div>
        ) : (
          <div>
            <div className="image-div">
              <img
                className="image-el"
                src="assets/images/staticFan.png"
                alt="Opps no internet..."
              />
            </div>
            <p>Disconnected from the Internet 😔</p>
          </div>
        )}
      </div>
      <div className="my-4 lg:my-2">
        {internetSpeed !== null && isOnline ? (
          <>
            {internetSpeed === 10 ? (
              <div className="flex flex-col gap-y-2">
                <p>You internet is {speedStatus}</p>
                <p className="text-lg text-[#595858] font-bold">
                  <b>{internetSpeed} Mbps</b>
                </p>
              </div>
            ) : (
              <div className="flex flex-col gap-y-2">
                <p>You internet is {speedStatus}</p>
                <p className="text-lg text-[#595858] font-bold">
                  <b>{internetSpeed} Mbps</b>
                </p>
              </div>
            )}
          </>
        ) : (
          <p>Checking internet Speed...</p>
        )}
      </div>
      <div className="flex justify-center w-full mb-6">
        <button
          className="button-btn hover:opacity-75"
          disabled={isCheckingStatus}
          onClick={measureConnectionSpeed}
        >
          {isCheckingStatus ? "Testing Internet Speed" : "Test Internet Speed"}
        </button>
      </div>
      <div className="fixed bottom-2 w-full text-center lg:text-left font-medium px-2">
        Try our browser extensions on{" "}
        <a
          className="text-red-500 underline"
          target="_blank"
          rel="noreferrer"
          href="https://chromewebstore.google.com/detail/internet-speed/hfdeahhjljnjddcjoglpodojkmeiahbl?authuser=0&hl=en"
        >
          Chrome
        </a>{" "}
        and{" "}
        <a
          className="text-red-500 underline"
          target="_blank"
          rel="noreferrer"
          href="https://addons.mozilla.org/en-US/firefox/addon/internet-speed/"
        >
          Mozilla
        </a>
      </div>
    </div>
  );
};

export default SpeedTestWeb;
